<template>
  <div
    class="home"
    :style="!isNoXCX ? 'padding-top: 0.426777rem;' : ''"
  >
    <template v-if="isNoXCX">
      <div class="all-title">区块链查证信息</div>
      <div
        class="common-back"
        @click="backAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20220428/c2e0865b1ace1cb7c56e7427381c234e.png"
          alt=""
        >
      </div>
    </template>

    <div class="img-desc common-bor">
      <div class="title-img item-flex-center">
        <img
          src="https://artimg2.artart.cn/image/20220428/1350543acb30029c2d2807d8104cec60.png"
          alt=""
        >
      </div>
      <div class="title item-flex-center">
        <div class="h"></div>
        <div class="c">区块链查证信息概览</div>
        <div class="h"></div>
      </div>
      <div class="items">
        <div
          class="item"
          :class="{ 'curr': showData }"
          @click="showDataAction"
        >
          <div>藏品数据</div>
          <img
            v-if="showData"
            src="https://artimg2.artart.cn/image/20220428/5ae3723d871fc1b782e11d876fbeea05.png"
            alt=""
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220428/55e5e72de4a92d202db3e9c1a6466b17.png"
            alt=""
          >
        </div>
        <div
          class="item-content"
          v-if="showData"
        >
          <div class="content-box">
            <div class="one-list">
              <div class="img">
                <img :src="detail.product.image + (detail.product.image.toLowerCase().indexOf('.gif') !== -1 ? '' : '!m640')">
              </div>
              <div class="text color-black">{{ detail.product.store_name }}</div>
              <div class="tags flex align-items-c flex-wrap">
                <div
                  class="tag"
                  v-for="(m, i) in detail.product.LabelNames"
                  :key="`tag${i}`"
                >{{ m }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="item"
          :class="{ 'curr': showHash }"
          @click="showHashAction"
        >
          <div>流转信息</div>
          <img
            v-if="showHash"
            src="https://artimg2.artart.cn/image/20220428/5ae3723d871fc1b782e11d876fbeea05.png"
            alt=""
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220428/55e5e72de4a92d202db3e9c1a6466b17.png"
            alt=""
          >
        </div>
        <div
          class="item-content"
          v-if="showHash"
        >
          <div
            v-for="(m, i) in detail.transfers"
            :key="`transfers${i}`"
          >
            <div class="item-c-t" :class="{ 'mt0': i == 0 }">
              <div>{{ m.recipientUser.nickname }}</div>
              <div class="bg"></div>
            </div>
            <div class="item-c-b">
              <div class="item-c-b-t">交易时间</div>
              <div class="item-c-b-c">{{ m.create_time }}</div>
              <div class="item-c-b-t">哈希值</div>
              <div class="item-c-b-c">{{ m.tx_hash }}</div>
            </div>
          </div>
          <div class="item-c-t" :class="{ 'mt0': (!detail.transfers || detail.transfers.length == 0) }">
            <div>{{ detail ? detail.buy_user_info.nickname : '' }}</div>
            <div class="bg"></div>
          </div>
          <div class="item-c-b">
            <div class="item-c-b-t">交易时间</div>
            <div class="item-c-b-c">{{ detail.create_time }}</div>
            <div class="item-c-b-t">哈希值</div>
            <div class="item-c-b-c">{{ detail.tx_hash }}</div>
          </div>
          <div class="bg-shu"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from "vue";
import { Image as VanImage, Toast } from "vant";
import { ownNftInfoApi, transfersApi } from "@/api/nft";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { returnWxPayType } from "@/utils/util";

export default {
  components: {
    [VanImage.name]: VanImage,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const id = ref("");
    const detail = ref("");

    onBeforeMount(() => {
      id.value = route.query.id;
      ownNftInfoApi(id.value)
        .then((res) => {
          detail.value = res.data;
        })
        .catch((message) => Toast(message));
    });

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };

    const showData = ref(false);
    const showDataAction = () => {
      showData.value = !showData.value;
    };
    const showHash = ref(true);
    const showHashAction = () => {
      showHash.value = !showHash.value;
    };

    return {
      detail,
      showData,
      showHash,
      isNoXCX: returnWxPayType() == "wechat_h5",

      backAction,
      showDataAction,
      showHashAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #1e1e1e;
  padding-bottom: 198px;
  max-height: 99999px;
  padding-top: 110px;
}

.common-bor {
  width: 686px;
  background: #333333;
  border-radius: 16px;
  border: 2px solid #444444;
  margin-left: 32px;
  box-sizing: border-box;
  padding: 32px;

  .title-img {
    img {
      width: 68px;
      height: 64px;
      display: block;
    }
  }

  .title {
    margin-top: 20px;
    .h {
      width: 60px;
      height: 4px;
      background: #fff;
    }
    .c {
      font-size: 32px;
      color: #fff;
      font-weight: 500;
      margin: 0 10px;
    }
  }
  .items {
    margin-top: 48px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 16px;
      height: 96px;
      font-size: 32px;
      font-weight: 500;
      color: #ffffff;
      border-bottom: 2px solid #444444;

      &.curr {
        border-color: #333;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
    .item-content {
      margin: 0 16px;
      padding: 32px;
      background: #444444;
      border-radius: 4px;
      position: relative;

      .bg-shu {
        position: absolute;
        left: 40px;
        bottom: 40px;
        width: 2px;
        background: #666;
        top: 50px;
        z-index: 1;
      }

      .item-c-t {
        padding-left: 48px;
        color: #fff;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        position: relative;
        margin-top: 48px;

        .bg {
          position: absolute;
          width: 18px;
          height: 18px;
          left: 0;
          top: 11px;
          border-radius: 50%;
          background: #00eeff;
          z-index: 2;
        }
      }
      .item-c-b {
        padding-left: 48px;
        font-size: 24px;
        color: #999;
        .item-c-b-t {
          margin-top: 32px;
        }
        .item-c-b-c {
          margin-top: 6px;
          word-break: break-all;
          color: #fff;
        }
      }
    }
  }
}

.mt32 {
  margin-top: 32px;
}

.content-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .one-list {
    overflow: hidden;
    padding-bottom: 32px;
    position: relative;

    .img {
      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    .text {
      padding: 20px 0 0 0;
      font-size: 32px;
      font-weight: 500;
      color: #fff;
    }

    .tags {
      padding: 6px 0 0 0;
      color: #00eeff;
      font-size: 24px;

      .tag {
        margin-top: 10px;
        line-height: 36px;
        height: 36px;
        border-radius: 2px;
        margin-right: 10px;
        padding: 0 8px;
        font-size: 22px;
        background: #5f5f5f;
        color: #fff;

        &:first-child {
          background: #00eeff;
          color: #000;
        }
      }

      .num {
        margin-top: 10px;
      }
    }
  }
}
</style>
